import React, {useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Link, IconButton, Button, Toolbar, AppBar, Grid, Drawer, List, ListItem, Divider} from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';
import ChevronRightRoundedIcon from '@material-ui/icons/ChevronRightRounded';
import ChevronLeftRoundedIcon from '@material-ui/icons/ChevronLeftRounded';
import ShuffleRoundedIcon from '@material-ui/icons/ShuffleRounded';
import ClearAllRoundedIcon from '@material-ui/icons/ClearAllRounded';

const drawerWidth = 200;


const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: '#fafafa',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  gridList: {
    width: '80%',
    height: '100vh',
  },
  link: {
      '&&:hover':{
        textDecoration: 'underline',
        textDecorationColor: '#0f2439',
      }
  },
  input: {
    "&&&:before": {
      borderBottom: "none",
    },
    "&&:after": {
      borderBottom: "none",
    },
    fontFamily: 'Open Sans', 
    fontSize: 18,
  },
}));


export default function Home() {

  const [quotes, setQuotes] = React.useState([])
  const [page, setPage] = React.useState(0);
  const [open, setOpen] = React.useState(false)



  const classes = useStyles();

  const handleChange = (event, value) => {
    setPage(value);
  };


  const handleDrawerClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    fetch('https://v2-api.sheety.co/acf105e053c2c6b5711368c10be6130d/ihQuotes/quotedump')
    .then(response => {
        return response.json()
    })
    .then(data => {
        setQuotes(data['quotedump'])
    });
  }, [])

  return (
      <>
       <Drawer
        docked
        elevation={0}
        open={open}
        onClose={handleDrawerClose}
        className={classes.drawer}
        // variant="permanent"
        classes={{
          paper: classes.drawerPaper,
        }}
        anchor="left"
      >
        <AppBar elevation={2} position="fixed" style={{color: 'black', backgroundColor: 'white', width: 200, left:0}}>
        <Toolbar >
         <Typography style={{fontWeight: 900, fontFamily: 'Playfair Display', wordBreak: 'break-word', fontSize: 16}}>
                      Quotes
          </Typography>
          <Divider style={{marginTop: 0, marginBottom: 8}}/>
          </Toolbar>
          </AppBar>
          <List style={{marginTop: 64}} id="nav-list">
          {quotes.map((item, index) => (
             <ListItem key={index}>
                <Button onClick={() => {
                  setPage(index)
                  setOpen(false)
                }}style={{textAlign: 'left', textDecoration: 'none', textTransform: 'none'}} disableRipple>
                    <Typography style={{fontWeight: 600, fontFamily: 'Playfair Display', wordBreak: 'break-word', fontSize: 12}}>
                      {item.quote}
                    </Typography>
                </Button>
             </ListItem>
          ))}
          </List>
      </Drawer>

    <div style={{margin: 16, borderRadius: 100, display: 'inline-block', padding: 8, backgroundColor: "#eeeeee"}}>
        <IconButton size="small" onClick={() => {
            if(page === 0){
                setPage(quotes.length -1 )
            } else {
                setPage(page - 1)
            }
        }}
        style={{marginRight: 8}}>
            <ChevronLeftRoundedIcon/>
        </IconButton>
        <IconButton size="small" onClick={() => {
            setPage((page + 1) % (quotes.length - 1))
        }}>
            <ChevronRightRoundedIcon/>
        </IconButton>    
            
    </div>
    <div style={{borderRadius: 100, display: 'inline-block', padding: 8, backgroundColor: "#eeeeee"}}>
        <IconButton size="small" style={{marginRight: 0}} onClick={() => {
            setPage(Math.round(Math.random() * quotes.length))
        }}>
            <ShuffleRoundedIcon fontSize="small"/>
        </IconButton>
       
    </div>
    <div style={{marginLeft: 16, borderRadius: 100, display: 'inline-block', padding: 8, backgroundColor: "#eeeeee"}}>
        <IconButton size="small" onClick={() => {
            setOpen(true)
        }}>
            <ClearAllRoundedIcon fontSize="small"/>
        </IconButton> 
       
    </div>
   
    <div className={classes.root}>
        
    <Grid style={{margin: '0 auto', width: '50%', marginTop: 64}} item xs={8} sm={6} md={3}>

              <Typography style={{fontWeight: 800, color: '#0f2439', fontFamily: 'Playfair Display', fontSize: 20}}>
                    
                    <span style={{wordBreak: 'break-word', lineHeight: '1.2em', backgroundColor: "rgba(255, 199, 0, 0.4)"}}>{quotes.length !== 0 ? quotes[page].quote : ""}</span>
              </Typography>
              <Link className={classes.link} target='_blank' rel='noopener' href={quotes.length !== 0 ? quotes[page].url : ""}>
              <Typography style={{wordBreak: 'break-word', fontWeight: 800, color: '#0f2439', fontFamily: 'Playfair Display', fontSize: 14, marginTop: 16}}>
                    
                    {quotes.length !== 0 ? quotes[page].byline.split(' of ')[0] : ""}
              </Typography>
              <Typography component="div" style={{fontWeight: 400, color: '#0f2439', fontFamily: 'Playfair Display', fontSize: 14}}>
                    
                {quotes.length !== 0 ? quotes[page].byline.split(' of ')[1] : ""}
                   
              </Typography>
              </Link>

              <Link  className={classes.link} target='_blank' rel='noopener' href={quotes.length !== 0 ? 'http://twitter.com/' + quotes[page].twitter : ""}>
               <Typography component="div" style={{marginTop: 32, fontWeight: 600, color: '#0f2439', fontFamily: 'Playfair Display', fontSize: 14}}>
                    
                {quotes.length !== 0 ? quotes[page].twitter : ""}
                   
              </Typography>
              </Link>
              
              
             </Grid>
             <div/>
    </div>  
    <div style={{padding: 16, width: '100%', backgroundColor: "rgba(255, 199, 0, 0.01)", position: 'fixed', bottom: 0}}>
     <Typography component="div" style={{fontWeight: 600, color: '#0f2439', fontFamily: 'Playfair Display', fontSize: 14}}>
                    
     <Link style={{color: '#0f2439'}} target='_blank' rel='noopener' href="https://indiehackers.com"> <b>Indie Hackers</b></Link><span> loading screen quotes  (+ some other interesting quotes)</span>
                       
    </Typography>
     </div> 

    </>
  );
}
